import store from '@/store'

export default {
  install (Vue) {
    // console.log('Type currency plugin')
    Vue.config.globalProperties.$CurrencyTypeDefinition = function (currency) {
      let type = ''
      switch (currency) {
        case 'zcash':
        case 'litecoin':
        case 'dogecoin':
        case 'dash':
        case 'bitcoingold':
        case 'bitcoincash':
        case 'bitcoin':
          type = 'btc'
          break
        case 'ethereum':
        case 'ethereumclassic':
          type = 'eth'
          break
      }
      return type
    }
    Vue.config.globalProperties.$GetCurrencyNameByCode = function (currencyCode) {
      return store.state.tokensCodeMap[currencyCode]?.name
    }
    Vue.config.globalProperties.$GetCurrencyCodeByName = function (currencyName) {
      return store.state.tokensNameMap[currencyName]?.code
    }
  }
}
